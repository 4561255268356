<template>
  <section :class="$style.wrap">
    <div class="index-title">
      <div>
        <img src="@/assets/icon_star.png" alt>
        <span>精品手游</span>
      </div>
      <p>EXCLUSIVE GAMES</p>
    </div>
    <div class="clearfix" :class="$style.body">
      <div :class="$style.cell" v-for="game in games" :key="game.id">
        <router-link
          :class="$style.cellHead"
          class="a-label-reset"
          :to="'/mobile-games/detail/'+game.id"
        >
          <img :src="game.icon" alt class="img-rounded img-responsive">
          <div class="description" style="margin-right: 0;">
            <p>{{game.name}}</p>
            <p>{{game.gameTypeName}}|{{game.gameSize}}M</p>
            <p>{{game.slogan}}</p>
          </div>
        </router-link>
        <div :class="$style.cellBottom">
          <button type="button" @click="downGame(game)">游戏下载</button>
        </div>
      </div>
    </div>
    <div class="foot">
      <router-link to="/mobile-games">更多游戏>></router-link>
    </div>
  </section>
</template>

<script>
import util from "../../commons/util.js";
import Const from "../../commons/const.js";

import axios from "axios";

export default {
  created() {
    axios
      .get("/game/listGame", {
        params: {
          pageNum: 1,
          pageRow: 4,
          operateSys: 2
        }
      })
      .then(response => {
        const list = response.returnData.list;
        if (list.length > 0) {
          this.games = list;
        }
      });
  },
  data() {
    return {
      games: []
    };
  },
  methods: {
    downGame(game) {
      if (device.ios()) {
        if (util.isEmpty(game.iosDownUrl)) {
          util.toast("上线在即，敬请期待^^");
        } else {
          window.open(game.iosDownUrl, "_blank");
        }
      } else {
        if (Const.weixin) {
          util.wxtip();
        } else {
          window.open(game.downUrl, "_blank");
        }
      }
    }
  }
};
</script>
<style module>
.body {
  border-top: 0.01rem solid rgb(231, 231, 231);
  border-bottom: 0.01rem solid rgb(231, 231, 231);
  min-height: 4.56rem;
}

.cell {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 0.3rem;
  border: 0.01rem solid rgb(231, 231, 231);
}

.cell:nth-child(1),
.cell:nth-child(3) {
  border-left: none;
}

.cell:nth-child(2),
.cell:nth-child(4) {
  border-right: none;
}

.cell-head {
  display: flex;
  align-items: center;
}

.cell-head img {
  width: 1.14rem;
  height: 1.14rem;
  margin-right: 0.2rem;
}

.cell-bottom {
  margin-top: 0.2rem;
}

.cell-bottom button {
  color: white;
  width: 100%;
  padding: 0.1rem;
  font-size: 0.27rem;
  background-color: #20a2dc;
  border-radius: 0.1rem;
  border: none;
}
</style>
