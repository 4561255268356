<template>
  <div>
    <mly-header></mly-header>

    <swiper :options="swiperOption" :class="$style.swiper" v-if="banners.length > 0">
      <!-- slides -->
      <swiper-slide v-for="(item,index) in banners" :key="index">
        <a @click="handleJump(item.jumpUrl)" :style="`background-image:url(${item.imgUrl})`"></a>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <game></game>

    <gift></gift>

    <section :class="$style.others" class="clearfix">
      <div>
        <img src="@/assets/gifts.png" alt class="img-responsive">
      </div>
      <div>
        <img src="@/assets/custom_service.png" alt class="img-responsive">
      </div>
    </section>

    <foot></foot>
    <mly-placeholder></mly-placeholder>
    <mly-toolbar></mly-toolbar>
  </div>
</template>
<script>
import axios from "axios";

import game from "./game";
import gift from "./gift";
import foot from "./foot";

export default {
  name: "index",
  components: {
    game,
    gift,
    foot
  },
  created: function() {
    axios.get("/banner/listBanner?operateSys=2").then(response => {
      this.banners = response.returnData.list;
    });
  },
  data() {
    return {
      banners: [],
      swiperOption: {
        // loop: true,   //如果轮播图需要循环播放的话，要更改实现方式，该属性会产生点击无效的情况
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  methods: {
    handleJump(url) {
      if (url.includes("http")) {
        location.href = url;
      } else {
        this.$router.push(url);
      }
    }
  }
};
</script>
<style>
.index-title {
  text-align: center;
  padding: 0.34rem 0;
}

.index-title img {
  width: 0.38rem;
  height: 0.31rem;
  position: relative;
  bottom: 0.1rem;
  right: 0.05rem;
}

.index-title span {
  font-size: 0.33rem;
}

.index-title p {
  color: #999999;
  font-size: 0.25rem;
}
</style>

<style module>
.swiper {
  border-bottom: 0.2rem solid #f4f4f4;
  height: 3.76rem;
}

.swiper a {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper :global(.swiper-pagination-bullet) {
  width: 0.2rem;
  height: 0.2rem;
  border: 0.02rem solid white;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.swiper :global(.swiper-pagination-bullet-active) {
  background-color: #20a3dc;
  border: none;
}

.others {
  padding: 0.31rem 0.25rem;
  border-bottom: 0.02rem solid #f4f4f4;
}

.others div {
  width: 50%;
  float: left;
}

.others div:nth-child(1) {
  border-right: 0.1rem solid white;
}

.others div:nth-child(2) {
  border-left: 0.1rem solid white;
}

.others img {
  width: 100%;
  height: 100%;
}
</style>
