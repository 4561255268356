<template>
  <footer :class="$style.wrap">
    <!-- <p>
            <a href="#">关于我们</a>
            <span>|</span>
            <a href="#">玩家客服</a>
            <span>|</span>
            <a href="#">商务合作</a>
            <span>|</span>
            <a href="#">隐私政策</a>
    </p>-->
    <!-- <p>Copyright © 2014 Baina. All Rights Reserved.</p> -->
    <p>健康游戏公告：抵制不良游戏 拒绝盗版游戏</p>
    <p>{{company}} 版权所有</p>
    <p>
      <a
        href="//beian.miit.gov.cn"
        target="_blank"
        v-if="domain==='254game'||domain==='127.0.0.1'"
      >粤ICP备17129008号</a>
      <a href="//beian.miit.gov.cn" target="_blank" v-else>沪ICP备18030015号</a>
    </p>
    <p>
      <a
        style="display: flex;justify-content: center;align-items: center;"
        class="a-label-reset"
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602001732"
        target="_blank"
        v-if="domain==='254game'||domain==='127.0.0.1'"
      >
        <img src="@/assets/ba_icon.png">粤公网安备 44030602001732号
      </a>
    </p>
    <p>
      <a :href="wwwUrl" target="_blank">
        <img src="@/assets/wenhuajingying.png">
      </a>
    </p>
  </footer>
</template>

<script>
import Const from "../../commons/const.js";

export default {
  computed: {
    //当前运行在哪个域名上
    domain() {
      if (Const.isDev) {
        return "127.0.0.1";
      }

      var __domain = document.domain;
      var __domain_array = __domain.split(".");
      if (__domain_array.length == 3) {
        __domain = __domain_array[1];
      } else if (__domain_array.length == 2) {
        __domain = __domain_array[0];
      }
      return __domain;
    },
    //公司名称
    company() {
      if (this.domain === "mlygame" || this.domain === "mlyol") {
        return "上海喵啰咿科技有限公司";
      } else {
        return "深圳市拓建源科技发展有限公司";
      }
    },
    //文网文 url
    wwwUrl() {
      return this.domain === "254game" || this.domain === "127.0.0.1"
        ? //拓建源的文网文信息地址
          "http://sq.ccm.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/1cdc7f8a6f824088b6cea45be2170d96"
        : //喵啰咿的文网文信息地址
          "http://sq.ccm.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/76CA28728F633E49E053010A14AC3198";
    }
  }
};
</script>

<style module>
.wrap {
  font-size: 0.23rem;
  padding: 0.2rem 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrap a,
.wrap p {
  color: #999999;
  text-decoration: none;
  margin-bottom: 0.24rem;
}

/* 备案信息样式 */

.wrap p:last-child img {
  width: 1rem;
  height: 1rem;
}

.wrap p:nth-child(4) a {
  color: #999999 !important;
}

.wrap p:nth-child(4) img {
  width: 0.4rem;
  height: 0.4rem;
}

.wrap p:nth-child(4) a:hover {
  text-decoration: underline !important;
}
</style>
