<template>
  <section>
    <div class="index-title">
      <div>
        <img src="@/assets/icon_gifts.png" alt>
        <span>礼包专区</span>
      </div>
      <p>EXCLUSIVE GIFTS</p>
    </div>
    <div :class="$style.banner">
      <img src="@/assets/banner_gifts.jpg" alt class="img-responsive">
    </div>
    <div :class="$style.body">
      <div class="list-item" v-for="gift in gifts" :key="gift.id">
        <img :src="gift.icon" alt class="img-rounded img-responsive">
        <div class="description">
          <p>{{gift.gameName}}</p>
          <p>{{gift.giftName}}</p>
          <p>
            剩余：
            <span class="num">{{gift.giftCount}}</span>
          </p>
        </div>
        <button type="button" @click="receiveCode(gift)">领号</button>
      </div>
    </div>
    <div class="foot">
      <router-link to="./gift">更多福利>></router-link>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Const from "../../commons/const.js";
import util from "../../commons/util.js";

export default {
  created() {
    axios
      .get("/gift/listGift", {
        params: {
          pageNum: 1,
          pageRow: 2
        }
      })
      .then(response => {
        const list = response.returnData.list;
        if (list.length > 0) {
          this.gifts = list;
        }
      });
  },
  data() {
    return {
      gifts: []
    };
  },
  methods: {
    receiveCode(gift) {
      if (!GlobalObject.User) {
        util.toast("请登录后再领取");
        return;
      }
      if (gift.giftCount < 1) {
        util.showPromptModal("当前礼包已经领取完了");
        return;
      }
      axios
        .post("/gift/getGift", {
          id: gift.id
        })
        .then(response => {
          if (response.returnCode == Const.RequestSuccess) {
            util.showGiftModal("response.returnData[0].code");
          } else {
            util.showPromptModal(response.returnMsg);
          }
        });
    }
  }
};
</script>

<style module>
.banner {
  min-height: 1.5rem;
}

.banner img {
  width: 100%;
}

.body .num {
  color: #27a4dd;
}
</style>
